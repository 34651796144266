import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter, Link, Routes, Route, useNavigate, useLocation, Navigate } from 'react-router-dom';
import { auth, db } from './firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import Auth from './components/Auth';
import Deck from './components/Deck';
import CharacterManager from './components/CharacterManager';
import SetManager from './components/SetManager';
import UserProfile from './components/UserProfile';
import './App.css';
import About from './pages/About';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Footer from './components/Footer';
import HamburgerMenu from './components/HamburgerMenu';

const AppContent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
  const [showSetManager, setShowSetManager] = useState(false);
  const [currentSet, setCurrentSet] = useState(null);
  const [charactersUpdated, setCharactersUpdated] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const refreshUserData = useCallback(async () => {
    if (user) {
      try {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const data = userDoc.data();
          const now = new Date();
          const graceEnd = new Date(data.graceEndDate);

          if (graceEnd && now > graceEnd) {
            await updateDoc(doc(db, 'users', user.uid), {
              tier: 'free',
              updatedAt: now.toISOString()
            });
            data.tier = 'free';
          }
          setUserData(data);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }
  }, [user]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      if (user) {
        const fetchUserData = async () => {
          try {
            const userDoc = await getDoc(doc(db, 'users', user.uid));
            if (userDoc.exists()) {
              setUserData(userDoc.data());
            }
          } catch (error) {
            console.error('Error fetching user data:', error);
          } finally {
            setLoading(false);
          }
        };
        fetchUserData();
      } else {
        setUserData(null);
        setLoading(false);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (user) {
      refreshUserData();
      const interval = setInterval(refreshUserData, 5 * 60 * 1000);
      return () => clearInterval(interval);
    }
  }, [user, refreshUserData]);

  const toggleOrderModal = () => {
    setIsOrderModalOpen(!isOrderModalOpen);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setCurrentSet(null);
      setUserData(null);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleSetSelect = (sets) => {
    setCurrentSet(sets);
    setShowSetManager(false);
    setCharactersUpdated(prev => prev + 1);
  };

  return (
    <div className="App">
      <div className="top-bar">
        <h1 className="title">
          <Link to="/" onClick={() => {
            setCurrentSet(null);
            setShowSetManager(false);
          }}>
            Hanzi Flashcards
          </Link>
        </h1>
        {user && (
          <>
            <div className="settings-menu">
              <button
                className="btn manage-sets-btn"
                onClick={() => {
                  navigate('/');
                  setShowSetManager(true);
                }}
              >
                Select Set
              </button>
              {currentSet && currentSet.length > 0 && !showResult && location.pathname === '/' && (
                <>
                  <button className="btn swap-button" onClick={toggleOrderModal}>
                    <span>Change Order</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <polyline points="1 4 1 10 7 10"></polyline>
                      <polyline points="23 20 23 14 17 14"></polyline>
                      <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path>
                    </svg>
                  </button>
                </>
              )}
              <button className="logout-btn" onClick={handleLogout}>
                Logout
              </button>
              <Link to="/profile" className="user-avatar">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                  <circle cx="12" cy="7" r="4" />
                </svg>
              </Link>
            </div>
            <HamburgerMenu
              isOpen={isMenuOpen}
              onToggle={() => setIsMenuOpen(!isMenuOpen)}
              onItemClick={(action) => {
                if (action === 'order') {
                  toggleOrderModal();
                } else {
                  navigate('/');
                  setShowSetManager(true);
                }
              }}
              user={user}
              currentSet={currentSet}
              showResult={showResult}
              location={location}
              handleLogout={handleLogout}
            />
          </>
        )}
      </div>
      <main>
        <Routes>
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/about" element={<About />} />
          <Route path="/terms" element={<Terms />} />

          <Route path="/profile" element={
            loading ? (
              <div className="loading-screen">Loading...</div>
            ) : user ? (
              <UserProfile user={user} userData={userData} loading={loading} />
            ) : (
              <Navigate to="/" state={{ from: location }} replace />
            )
          } />

          <Route path="/manage-set/:setId" element={
            loading ? (
              <div className="loading-screen">Loading...</div>
            ) : user ? (
              <CharacterManager userId={user.uid} userData={userData} />
            ) : (
              <Navigate to="/" state={{ from: location }} replace />
            )
          } />

          <Route path="/" element={
            loading ? (
              <div className="loading-screen">Loading...</div>
            ) : user ? (
              <>
                {currentSet ? (
                  <Deck
                    isOrderModalOpen={isOrderModalOpen}
                    setIsOrderModalOpen={setIsOrderModalOpen}
                    userId={user.uid}
                    userData={userData}
                    currentSet={currentSet}
                    charactersUpdated={charactersUpdated}
                    showResult={showResult}
                    setShowResult={setShowResult}
                    onManageCharacters={() => navigate(`/manage-set/${currentSet[0].id}`)}
                  />
                ) : (
                  <div className="empty-state">
                    <h2>Select a Set</h2>
                    <p>Please select or create a character set to start practicing</p>
                    <button
                      className="btn btn-primary"
                      onClick={() => setShowSetManager(true)}
                    >
                      Select Set
                    </button>
                  </div>
                )}
                {showSetManager && (
                  <div className="modal-overlay">
                    <SetManager
                      userId={user.uid}
                      userData={userData}
                      onClose={() => setShowSetManager(false)}
                      onSelectSet={handleSetSelect}
                      onManageCharacters={(set) => navigate(`/manage-set/${set.id}`)}
                    />
                  </div>
                )}
              </>
            ) : (
              <Auth />
            )
          } />
        </Routes>
      </main>
      <Footer />
    </div>
  );
};

function App() {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
}

export default App;