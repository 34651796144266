import React from 'react';
import { Link } from 'react-router-dom';
import './About.css';

const About = () => {
  return (
    <div className="page-container about">
      <header className="about-header">
        <h1>About Hanzi Flashcards</h1>
        <h2>Master Chinese Characters Your Way</h2>
        <p className="intro">
          Hanzi Flashcards is an innovative web application designed to help you learn Chinese characters effectively through customizable triple-sided flashcards. Whether you're studying Simplified or Traditional Chinese, our unique approach lets you tailor your learning experience to focus on character recognition, pronunciation, or translation skills.
        </p>
      </header>

      <section className="why-choose">
        <h2>Why Choose Hanzi Flashcards?</h2>

        <div className="feature-block">
          <h3>Unique Triple-Sided Learning</h3>
          <p>Our flashcards feature three essential elements of Chinese character learning:</p>
          <ul>
            <li>Hanzi characters (both Simplified and Traditional Chinese)</li>
            <li>Pinyin pronunciation</li>
            <li>Translation</li>
          </ul>
        </div>

        <div className="feature-block">
          <h3>Flexible Learning Approach</h3>
          <p>Unlike traditional flashcard apps, Hanzi Flashcards lets you choose which side appears first:</p>
          <ul>
            <li>Start with characters to enhance recognition skills</li>
            <li>Begin with translations to improve Chinese production</li>
            <li>Focus on pinyin to strengthen pronunciation and listening abilities</li>
          </ul>
        </div>

        <div className="feature-block">
          <h3>Personalized Learning Sets</h3>
          <ul>
            <li>Create custom sets of flashcards</li>
            <li>Combine multiple sets for comprehensive practice</li>
            <li>Focus on challenging characters with wrong-answer review sessions</li>
          </ul>
        </div>

        <div className="feature-block">
          <h3>Progress Tracking</h3>
          <p>Monitor your learning progress with detailed test results after each practice session, helping you identify areas that need more attention.</p>
        </div>
      </section>

      <section className="features">
        <h2>Features</h2>

        <div className="feature-block">
          <h3>Core Functionality</h3>
          <ul>
            <li>Triple-sided flashcards (Character, Pinyin, Translation)</li>
            <li>Customizable display order</li>
            <li>Support for both Simplified and Traditional Chinese</li>
            <li>User-defined flashcard sets</li>
            <li>Wrong-answer review mode</li>
          </ul>
        </div>


        <div className="feature-block">
          <h3>Data Import</h3>
          <p>Easy import of character sets via:</p>
          <ul>
            <li>AI Translation - instantly translate up to 20 characters (free tier) or 50 characters (premium)</li>
            <li>7-day free trial of AI translation for new users</li>
            <li>Premium subscribers get permanent access to AI translation</li>
          </ul>
        </div>
        <div className="feature-block hidden">
          <h3>Data Import</h3>
          <p>Easy import of character sets via CSV files in the format:</p>
          <pre className="csv-example">{`Character,Pinyin,Translation
的,de,of/possessive particle
一,yī,one`}
          </pre>
        </div>

        <div className="feature-block">
          <h3>Progressive Web App</h3>
          <ul>
            <li>Install on any device for quick access</li>
            <li>Works across all modern browsers</li>
            <li>Responsive design for desktop and mobile use</li>
          </ul>
        </div>
      </section>

      <section className="pricing">
        <h2>Subscription Plans</h2>
        <div className="plans">
          <div className="plan-block">
            <h3>Free Tier</h3>
            <ul>
              <li>Up to 5 sets</li>
              <li>Up to 20 flashcards per set</li>
              <li>Perfect for trying out the system</li>
              <li>All core features included</li>
            </ul>
          </div>

          <div className="plan-block">
            <h3>Monthly Plan ($1.99/month)</h3>
            <ul>
              <li>Unlimited flashcards</li>
              <li>All features included</li>
              <li>Create unlimited sets</li>
            </ul>
          </div>

          <div className="plan-block">
            <h3>Annual Plan ($19.99/year)</h3>
            <ul>
              <li>Unlimited flashcards</li>
              <li>All features included</li>
              <li>Create unlimited sets</li>
              <li>Save 16% compared to monthly</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="future">
        <h2>Future Development</h2>
        <p>We're committed to continuous improvement and will be adding new features based on user feedback and needs. Your suggestions are welcome as we grow and enhance the application.</p>
      </section>

      <section className="get-started">
        <h2>Get Started</h2>
        <p>Start your Chinese learning journey today with Hanzi Flashcards. Try our free tier to experience the unique benefits of triple-sided flashcards and discover why they're more effective for learning Chinese characters.</p>
        <Link to="/" className="cta-button">Try It Now</Link>
      </section>
    </div>
  );
};

export default About;