import React from 'react';
import '../styles/Modal.css';

const ImportSuccessModal = ({ count, onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content success-modal">
        <h2>Import Successful!</h2>
        <p>{count} characters were added to your set</p>
        <button className="btn btn-primary" onClick={onClose}>
          OK
        </button>
      </div>
    </div>
  );
};

export default ImportSuccessModal;