import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { db } from '../firebase';
import { collection, addDoc, query, where, getDocs, deleteDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
// import CSVUploader from './CSVUploader';
import './CharacterManager.css';
import AICharacterImport from './AICharacterImport'

const CharacterManager = ({ userId, userData }) => {
  const { setId } = useParams();
  const navigate = useNavigate();
  const [characters, setCharacters] = useState([]);
  const [setName, setSetName] = useState('');
  const [newCharacter, setNewCharacter] = useState({
    hanzi: '',
    pinyin: '',
    translation: ''
  });
  const [error, setError] = useState('');
  const [editingChar, setEditingChar] = useState(null);
  const [editForm, setEditForm] = useState({
    hanzi: '',
    pinyin: '',
    translation: ''
  });

  const fetchSet = useCallback(async () => {
    const docRef = doc(db, 'sets', setId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setSetName(docSnap.data().name);
    }
  }, [setId]);

  const fetchCharacters = useCallback(async () => {
    const q = query(
      collection(db, 'characters'),
      where('userId', '==', userId),
      where('setId', '==', setId)
    );
    const querySnapshot = await getDocs(q);
    setCharacters(querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })));
  }, [userId, setId]);

  useEffect(() => {
    const init = async () => {
      await fetchSet();
      await fetchCharacters();
    };
    init();
  }, [fetchSet, fetchCharacters]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (userData?.tier === 'free') {
      const count = characters.length;
      if (count >= 20) {
        setError('Free tier limited to 20 cards per set. Please upgrade.');
        return;
      }
    }

    if (!newCharacter.hanzi || !newCharacter.pinyin || !newCharacter.translation) {
      setError('All fields are required');
      return;
    }

    try {
      await addDoc(collection(db, 'characters'), {
        ...newCharacter,
        userId,
        setId
      });
      setNewCharacter({ hanzi: '', pinyin: '', translation: '' });
      setError('');
      fetchCharacters();
    } catch (error) {
      setError('Error adding character');
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, 'characters', id));
      fetchCharacters();
    } catch (error) {
      setError('Error deleting character');
    }
  };

  const handleDeleteSet = async () => {
    if (window.confirm('Are you sure? All characters in this set will be deleted.')) {
      try {
        const charsQuery = query(
          collection(db, 'characters'),
          where('setId', '==', setId)
        );
        const charsSnapshot = await getDocs(charsQuery);
        const deletePromises = charsSnapshot.docs.map(doc =>
          deleteDoc(doc.ref)
        );
        await Promise.all(deletePromises);

        await deleteDoc(doc(db, 'sets', setId));
        navigate('/');
      } catch (error) {
        setError('Error deleting set');
      }
    }
  };

  const handleEdit = (char) => {
    setEditingChar(char.id);
    setEditForm({
      hanzi: char.hanzi,
      pinyin: char.pinyin,
      translation: char.translation
    });
  };

  const handleUpdate = async () => {
    if (!editForm.hanzi || !editForm.pinyin || !editForm.translation) {
      setError('All fields are required');
      return;
    }

    try {
      await updateDoc(doc(db, 'characters', editingChar), editForm);
      setEditingChar(null);
      setError('');
      fetchCharacters();
    } catch (error) {
      setError('Error updating character');
    }
  };

  return (
    <div className="character-manager-page">
      <div className="header">
        <button className="btn btn-secondary" onClick={() => navigate('/')}>
          Back to Sets
        </button>
        <h1>Managing Characters in "{setName}"</h1>
      </div>

      <div className="content">
        {/* <div className="upload-section">
          <h3>Import from CSV</h3>
          <CSVUploader
            userId={userId}
            userData={userData}
            setId={setId}
            onUploadComplete={fetchCharacters}
          />
        </div> */}

        {error && <p className="error">{error}</p>}

        <div className="upload-section">
          {userData?.tier !== 'free' || new Date() < new Date(userData.trialEndDate ||
            new Date(userData.createdAt).getTime() + 7 * 24 * 60 * 60 * 1000) ? (
            <AICharacterImport
              userId={userId}
              userData={userData}
              setId={setId}
              onComplete={async () => fetchCharacters()}
            />
          ) : (
            <div className="upgrade-message">
              <p>Want to translate more characters at once? Upgrade to Premium and enjoy AI translation for up to 50 characters per batch.</p>
              <Link to="/profile" className="btn btn-primary">Upgrade Now</Link>
            </div>
          )}
        </div>

        <form onSubmit={handleSubmit} className="character-form">
          <input
            type="text"
            placeholder="Hanzi"
            value={newCharacter.hanzi}
            onChange={(e) => setNewCharacter(prev => ({ ...prev, hanzi: e.target.value }))}
          />
          <input
            type="text"
            placeholder="Pinyin"
            value={newCharacter.pinyin}
            onChange={(e) => setNewCharacter(prev => ({ ...prev, pinyin: e.target.value }))}
          />
          <input
            type="text"
            placeholder="Translation"
            value={newCharacter.translation}
            onChange={(e) => setNewCharacter(prev => ({ ...prev, translation: e.target.value }))}
          />
          <button type="submit" className="btn btn-primary">Add Character</button>
        </form>

        <div className="characters-list">
          {characters.map(char => (
            <div key={char.id} className="character-item">
              {editingChar === char.id ? (
                <div className="character-edit-form">
                  <input
                    type="text"
                    value={editForm.hanzi}
                    onChange={(e) => setEditForm(prev => ({ ...prev, hanzi: e.target.value }))}
                    placeholder="Hanzi"
                  />
                  <input
                    type="text"
                    value={editForm.pinyin}
                    onChange={(e) => setEditForm(prev => ({ ...prev, pinyin: e.target.value }))}
                    placeholder="Pinyin"
                  />
                  <input
                    type="text"
                    value={editForm.translation}
                    onChange={(e) => setEditForm(prev => ({ ...prev, translation: e.target.value }))}
                    placeholder="Translation"
                  />
                  <div className="edit-actions">
                    <button onClick={handleUpdate} className="btn btn-primary">Save</button>
                    <button onClick={() => setEditingChar(null)} className="btn btn-secondary">Cancel</button>
                  </div>
                </div>
              ) : (
                <>
                  <div className="character-details" onClick={() => handleEdit(char)}>
                    <span className="hanzi">{char.hanzi}</span>
                    <span className="pinyin">{char.pinyin}</span>
                    <span className="translation">{char.translation}</span>
                  </div>
                  <button onClick={() => handleDelete(char.id)} className="delete-btn">×</button>
                </>
              )}
            </div>
          ))}
        </div>

        <div className="delete-set-container">
          <button
            className="btn btn-danger"
            onClick={handleDeleteSet}
          >
            Delete Set
          </button>
        </div>
      </div>
    </div>
  );
};

export default CharacterManager;