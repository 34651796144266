import React, { useState } from 'react';
import Login from './Login';
import SignUp from './SignUp';
import './Auth.css';

const Auth = () => {
  const [activeTab, setActiveTab] = useState('login');

  return (
    <div className="auth-container">
      <div className="auth-card">
        <div className="auth-tabs">
          <button
            className={`auth-tab ${activeTab === 'login' ? 'active' : ''}`}
            onClick={() => setActiveTab('login')}
          >
            Login
          </button>
          <button
            className={`auth-tab ${activeTab === 'signup' ? 'active' : ''}`}
            onClick={() => setActiveTab('signup')}
          >
            Sign Up
          </button>
        </div>
        <div className="auth-content">
          {activeTab === 'login' ? <Login /> : <SignUp />}
        </div>
      </div>
    </div>
  );
};

export default Auth;