import React from 'react';
import './Flashcard.css';

const Flashcard = ({ data, currentSide, onFlip }) => {
  if (!data || !data[currentSide]) {
    return null;
  }

  return (
    <div className="flashcard" onClick={onFlip}>
      <div className="flashcard-content">
        <div className={`flashcard-text ${currentSide === 'hanzi' ? 'hanzi' : ''}`}>
          {data[currentSide]}
        </div>
      </div>
    </div>
  );
};

export default Flashcard;