import React, { useState } from 'react';
import { updatePassword, deleteUser } from 'firebase/auth';
import ReauthModal from './ReauthModal';
import { auth, db } from '../firebase';
import { deleteDoc, doc } from 'firebase/firestore';
import ChangePasswordModal from './ChangePasswordModal';
import DeleteAccountModal from './DeleteAccountModal';
import './UserProfile.css';

const UserProfile = ({ user, userData, loading }) => {
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showReauthModal, setShowReauthModal] = useState(false);
  const [pendingAction, setPendingAction] = useState(null);

  const handleChangePassword = async (newPassword) => {
    setPendingAction(() => async () => {
      try {
        await updatePassword(auth.currentUser, newPassword);
        setMessage('Password updated successfully');
        setShowPasswordModal(false);
      } catch (error) {
        setError('Error updating password');
      }
    });
    setShowReauthModal(true);
  };

  const handleDeleteAccount = async () => {
    setPendingAction(() => async () => {
      try {
        await deleteDoc(doc(db, 'users', user.uid));
        await deleteUser(auth.currentUser);
      } catch (error) {
        setError('Error deleting account');
      }
    });
    setShowReauthModal(true);
  };

  const handleReauthSuccess = async () => {
    setShowReauthModal(false);
    if (pendingAction) {
      await pendingAction();
      setPendingAction(null);
    }
  };

  if (loading) {
    return <div className="profile-container">Loading...</div>;
  }

  const handleSubscribeMonthly = () => {
    window.open('https://store.payproglobal.com/checkout?products[1][id]=104742', '_blank', 'noopener');
  };

  const handleSubscribeAnnual = () => {
    window.open('https://store.payproglobal.com/checkout?products[1][id]=104239', '_blank', 'noopener');
  };

  return (
    <div className="profile-container">
      {error && <div className="error">{error}</div>}
      {message && <div className="success">{message}</div>}
      <h1>User Profile</h1>

      <div className="profile-section">
        <h2>Account Details</h2>
        <p><strong>Email:</strong> {user.email}</p>
      </div>

      <div className="profile-section">
        <h2>Subscription</h2>
        <div className="subscription-info">
          <p><strong>Current Tier:</strong> {userData?.tier || 'Free'}</p>
          {(!userData?.tier || userData?.tier === 'free') && (
            <div className="upgrade-section">
              <h3>Upgrade Your Account</h3>
              <div className="subscription-options">
                <div className="subscription-card">
                  <h4>Monthly Plan</h4>
                  <p className="price">$1.99/month</p>
                  <ul>
                    <li>Unlimited sets</li>
                    <li>Unlimited cards per set</li>
                    <li>All features included</li>
                  </ul>
                  <button className="btn btn-primary" onClick={handleSubscribeMonthly}>
                    Subscribe Monthly
                  </button>
                </div>
                <div className="subscription-card">
                  <h4>Annual Plan</h4>
                  <p className="price">$19.99/year</p>
                  <p className="savings">Save 16%</p>
                  <ul>
                    <li>Unlimited sets</li>
                    <li>Unlimited cards per set</li>
                    <li>All features included</li>
                  </ul>
                  <button className="btn btn-primary" onClick={handleSubscribeAnnual}>
                    Subscribe Annually
                  </button>
                </div>
              </div>
            </div>
          )}
          {userData?.tier && userData.tier !== 'free' && (
            <div className="subscription-details">
              <p><strong>Next Payment:</strong> {new Date(userData.subscriptionEndDate).toLocaleDateString()}</p>
              <p><strong>Amount:</strong> ${userData.nextBillingAmount} {userData.nextBillingCurrency}</p>
              {userData.lastFourDigits && (
                <p><strong>Card:</strong> **** **** **** {userData.lastFourDigits}</p>
              )}
              {userData.tier === 'monthly' && (
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={handleSubscribeAnnual}
                    style={{ marginTop: '15px' }}
                  >
                    Switch to Annual Plan (Save 16%)
                  </button>
                  <p className="upgrade-note">
                    When upgrading, your new annual subscription will start from your current monthly end date
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="profile-section">
        <h2>Account Management</h2>
        <div className="management-buttons">
          <button
            className="btn btn-secondary"
            onClick={() => setShowPasswordModal(true)}
          >
            Change Password
          </button>
          <button
            className="btn btn-danger"
            onClick={() => setShowDeleteModal(true)}
          >
            Delete Account
          </button>
        </div>
      </div>

      {showPasswordModal && (
        <ChangePasswordModal
          onClose={() => setShowPasswordModal(false)}
          onSubmit={handleChangePassword}
        />
      )}

      {showDeleteModal && (
        <DeleteAccountModal
          onClose={() => setShowDeleteModal(false)}
          onConfirm={handleDeleteAccount}
        />
      )}

      {showReauthModal && (
        <ReauthModal
          onClose={() => {
            setShowReauthModal(false);
            setPendingAction(null);
          }}
          onSuccess={handleReauthSuccess}
        />
      )}
    </div>
  );
};

export default UserProfile;