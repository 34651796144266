import React, { useState } from 'react';
import Papa from 'papaparse';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase';
import ImportSuccessModal from './ImportSuccessModal';
import './AICharacterImport.css';

const AICharacterImport = ({ userId, userData, setId, onComplete }) => {
  const [characters, setCharacters] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [importedCount, setImportedCount] = useState(0);
  const [charCount, setCharCount] = useState(0);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!characters.trim()) {
      setError('Please enter some characters');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const response = await fetch('/.netlify/functions/openai-translate', {
        method: 'POST',
        body: JSON.stringify({
          characters: characters.trim(),
          userId
        })
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to process characters');
      }

      Papa.parse(data.csv, {
        header: true,
        skipEmptyLines: true,
        delimiter: ",",
        complete: async (results) => {
          try {
            const characterData = results.data.map(row => ({
              hanzi: row.Character,
              pinyin: row.Pinyin,
              translation: row.Translation,
              userId,
              setId
            }));

            const addPromises = characterData.map(char =>
              addDoc(collection(db, 'characters'), char)
            );
            await Promise.all(addPromises);
            setImportedCount(characterData.length);
            setShowSuccess(true);
            await onComplete();
            setCharacters('');

            document.querySelector('.characters-list').scrollIntoView({
              behavior: 'smooth',
              block: 'start'
            });
          } catch (err) {
            console.error('Error processing character data:', err)
            setError('Error processing character data');
          }
        },
        error: (err) => {
          console.error('Error parsing response:', err);
          setError('Error parsing response');
        }
      });
    } catch (err) {
      console.error('Error:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="ai-import">
      <h3>Import with AI Translation</h3>
      <p className="description">
        {userData?.tier === 'free'
          ? 'Paste up to 20 characters and we\'ll get their pinyin and translations'
          : 'Paste up to 50 characters and we\'ll get their pinyin and translations'}
      </p>
      <form onSubmit={handleSubmit}>
        <textarea
          value={characters}
          onChange={(e) => {
            setCharacters(e.target.value);
            const uniqueChineseChars = new Set(e.target.value.match(/[\u4e00-\u9fff]/g) || []).size;
            setCharCount(uniqueChineseChars);
          }}
          placeholder="Paste Chinese characters here"
          rows={4}
        />
        <div className="char-counter">
          {charCount}/{userData?.tier === 'free' ? '20' : '50'} unique Hanzi characters
        </div>
        {error && <div className="error">{error}</div>}
        <button
          type="submit"
          className="btn btn-primary"
          disabled={loading || !characters.trim()}
        >
          {loading ? 'Processing...' : 'Import Characters'}
        </button>
      </form>
      {showSuccess && (
        <ImportSuccessModal
          count={importedCount}
          onClose={() => setShowSuccess(false)}
        />
      )}
    </div>
  );
};

export default AICharacterImport;